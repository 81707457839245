import * as S from "./styles";

function WelcomeFourth() {
  return (
    <div>
      <S.Title>
        Receba <span>dinheiro</span> de volta
      </S.Title>

      <S.Line />

      <S.SubTitle>
        Adquira <strong>produtos da loja</strong> e acumule
        <strong> Profitz</strong>, nossas <br /> moedas virtuais.
      </S.SubTitle>
    </div>
  );
}

export { WelcomeFourth };
