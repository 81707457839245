import functionalities from "../images/welcome-2-functionalities.png";

import * as S from "./styles";

function WelcomeSecond() {
  return (
    <div>
      <S.Title>
        Explore todas as <br />
        <span>funcionalidades</span>
      </S.Title>

      <S.Line />

      <div>
        <img src={functionalities} alt="funcionalidades" />
      </div>

      <S.SubTitle>E muito mais!</S.SubTitle>
    </div>
  );
}

export { WelcomeSecond };
