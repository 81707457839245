import React from "react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";

import App from "./App";

const tagManagerArgs = {
  gtmId: "GTM-M8Q4Q9W",
};

const cookiesArray = document.cookie.split(";");

const cookieConsentPerformance = cookiesArray.find((cookie) =>
  cookie.includes(" _cookie_consent_performance=true")
);

if (cookieConsentPerformance) {
  TagManager.initialize(tagManagerArgs);
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
