import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.strong_background};
    height: 100vh;
    display: flex;
    max-width: 100%;
  `}
`;

export const ImageContent = styled.div`
  height: 100vh;
  width: 50%;
`;

export const WrapperContent = styled.div`
  height: 100vh;
  width: 50%;
`;

export const WrapperSkipButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;
  margin-right: 30px;
`;

export const SkipButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    font-family: ${theme.font.family};
    width: 50px;
    height: 50px;
  `};
`;

export const WrapperTextInformation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
`;

export const WrapperFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
`;
