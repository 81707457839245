import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";

import GlobalStyles from "styles/global";

import { ResearchRoutes } from "routes/research.routes";
import { DownloadRoutes } from "routes/download.routes";
import { MobilePage } from "pages/common/MobilePage";
import { EventRoutes } from "routes/event.routes";
import { IndicationRoutes } from "routes/indication.routes";
import { Routes } from "./routes";

import AppProvider from "./hooks";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { PermissionPublicRoutes } from "routes/permission-public.routes";
import { PublicRoutes } from "routes/public.routes";
import { ModalCookieAcceptance } from "components/ModalCookieAcceptance";

function App() {
  const [isFromMobile, setIsFromMobile] = useState(false);
  const [isVisibleModalCookieAcceptance, setIsVisibleModalCookieAcceptance] =
    useState(false);

  useEffect(() => {
    const isPublic = window.location.href.search("public") > 0;
    if (isPublic) return;
    setIsFromMobile(isMobile && !isPublic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    checkCookiePolicyAndPrivacyExists();
  }, []);

  const checkCookiePolicyAndPrivacyExists = () => {
    if (isMobile) {
      return;
    }

    const cookiesArray = document.cookie.split(";");

    const cookieConsent = cookiesArray.find((cookie) =>
      cookie.includes("_cookie_consent")
    );

    setIsVisibleModalCookieAcceptance(!cookieConsent);
  };

  return (
    <>
      <AppProvider>
        <BrowserRouter>
          <GoogleOAuthProvider clientId="830903563096-8pt5ab863083t0mb0mn19ccgctmbrae0.apps.googleusercontent.com">
            <EventRoutes />
            <IndicationRoutes />
            <ResearchRoutes />
            <PermissionPublicRoutes />
            <DownloadRoutes />
            <PublicRoutes />
            <ModalCookieAcceptance
              isVisible={isVisibleModalCookieAcceptance}
              setIsVisible={setIsVisibleModalCookieAcceptance}
            />
            {!isFromMobile ? <Routes /> : <MobilePage />}
          </GoogleOAuthProvider>
          <GlobalStyles />
        </BrowserRouter>
      </AppProvider>
    </>
  );
}

export default App;
