import { useState } from "react";
import { useAuth } from "hooks/auth";
import * as S from "./styles";
import { MdClose } from "react-icons/md";

type ModalProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
};

function ModalCookieAcceptance({ isVisible, setIsVisible }: ModalProps) {
  const { user } = useAuth();

  const [cookieAdministrationVisible, setCookieAdministrationVisible] =
    useState(false);
  const [cookiePerformanceChecked, setCookiePerformanceChecked] =
    useState(true);

  const handleAcceptCookies = () => {
    let expires = "";

    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;

    document.cookie = `_cookie_consent=hide${expires}; path=/`;
    document.cookie = `_cookie_consent_performance=${
      cookiePerformanceChecked ? `true` : `false`
    }${expires}; path=/`;

    setIsVisible(false);
    setCookieAdministrationVisible(false);

    window.location.reload();
  };

  const handleNavigatePrivacyPolicies = () => {
    return user?.id ? "/privacy-policies" : "/public/privacy-policies";
  };

  if (isVisible && !cookieAdministrationVisible) {
    return (
      <S.BoxAlert>
        <p>
          Clicando em "Aceito todos os Cookies", você concorda com a nossa
          <a
            href={handleNavigatePrivacyPolicies()}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Política de Privacidade{" "}
          </a>
          e com o armazenamento de cookies no seu dispositivo para melhorar a
          experiência e navegação no site.
        </p>

        <S.WrapperButtons>
          <S.ButtonPreferences
            onClick={() => setCookieAdministrationVisible(true)}
          >
            Preferências
          </S.ButtonPreferences>
          <S.ButtonAcceptAllCookies onClick={handleAcceptCookies}>
            Aceitar todos
          </S.ButtonAcceptAllCookies>
        </S.WrapperButtons>
      </S.BoxAlert>
    );
  }

  if (isVisible && cookieAdministrationVisible) {
    return (
      <S.BoxPreferencesCookies>
        <S.BoxHeader>
          <div>
            <button onClick={() => setCookieAdministrationVisible(false)}>
              <MdClose size={20} />
            </button>
          </div>

          <h1>Gestão de cookies</h1>
          <a
            href={handleNavigatePrivacyPolicies()}
            target="_blank"
            rel="noreferrer"
          >
            Política de Privacidade
          </a>
        </S.BoxHeader>

        <S.BoxContent>
          <div>
            <h3>Cookies necessários</h3>
            <p>
              Estes cookies são aqueles necessários para o site funcionar e não
              podem ser desligados em nossos sistemas. Eles geralmente são
              definidos apenas em resposta às ações feitas por você, como por
              exemplo, definir suas preferências de privacidade, fazer login ou
              preencher formulários. Caso queira, pode configurar seu navegador
              para bloqueá-lo ou alertá-lo sobre esses cookies, mas algumas
              partes do site podem não funcionar de forma adequada.
            </p>
          </div>

          <div>
            <h3>
              Cookies de desempenho{" "}
              <S.ToggleContainer>
                <S.HiddenCheckbox
                  checked={cookiePerformanceChecked}
                  onChange={() =>
                    setCookiePerformanceChecked(!cookiePerformanceChecked)
                  }
                />
                <S.Slider checked={cookiePerformanceChecked} />
              </S.ToggleContainer>
            </h3>

            <p>
              Os cookies de desempenho fornecem informações sobre como este site
              está sendo usado para que possamos melhorar a experiência do
              usuário. Os dados capturados são agregados e anonimizados.
            </p>
          </div>
        </S.BoxContent>

        <S.BoxFooter>
          <span>
            Você pode modificar suas preferências de privacidade a qualquer
            momento.
          </span>

          <S.WrapperButtons style={{ justifyContent: "center", marginTop: 10 }}>
            <S.ButtonPreferences onClick={handleAcceptCookies}>
              Salvar preferências
            </S.ButtonPreferences>
            <S.ButtonAcceptAllCookies
              onClick={handleAcceptCookies}
              style={{ width: 200 }}
            >
              Aceitar todos os cookies
            </S.ButtonAcceptAllCookies>
          </S.WrapperButtons>
        </S.BoxFooter>
      </S.BoxPreferencesCookies>
    );
  }

  return <></>;
}

export { ModalCookieAcceptance };
