import * as S from "./styles";

function WelcomeFirst() {
  return (
    <div>
      <S.Title>
        Experiência completa e <br /> <span>gratuita</span>
      </S.Title>

      <S.Line />

      <S.SubTitle>
        <strong>Finalize</strong> o cadastro e comece a
        <strong> organizar</strong> seus <br /> serviços de qualquer lugar!
      </S.SubTitle>
    </div>
  );
}

export { WelcomeFirst };
