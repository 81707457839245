import { HiOutlinePlus } from "react-icons/hi";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory, useLocation } from "react-router";

import { ArrowButton } from "components/ArrowButton";

import { Pending } from "./Pending";
import { ArchivedServiceOrder } from "./Archived";
import { Concluded } from "./Concluded";

import "react-tabs/style/react-tabs.css";
import * as S from "./styles";
import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";
import { useAccount } from "hooks/permission/account";
import { useEffect, useState } from "react";
import OnboardingCard from "components/OnboardingCard";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";
import { updateUserOnboarding } from "utils/updateUserOnboarding";
import { api } from "services/api";

type LocationProps = {
  before?: string;
};

export function ServiceOrder() {
  const { whoami } = useAccount();
  const history = useHistory();
  const location = useLocation<LocationProps>();
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();

  const [tourModalVisible, setTourModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);

  useEffect(() => {
    const userSeenOnboarding = user.onboarding.serviceOrderOnboarding;
    if (!userSeenOnboarding) {
      setTourModalVisible(true);
    }

    if (user?.profileDisapproved) {
      setActiveModalRejectedRegistration(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateUserOnboardingServiceOrder() {
    try {
      const updateUserData = updateUserOnboarding(
        user,
        "serviceOrderOnboarding"
      );

      await api.put("/users/me", updateUserData);
      updateUser(updateUserData);
    } catch (error) {
      addToast({
        title: "Erro",
        description: "Ocorreu um erro ao atualizar o usuário.",
        type: "error",
      });
    }
  }

  return (
    <S.Container>
      <OnboardingCard
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isTourModalVisible={tourModalVisible}
        setIsTourModalVisible={setTourModalVisible}
        onUpdateOnboarding={updateUserOnboardingServiceOrder}
        type={"serviceOrderOnboarding"}
      />
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      <header>
        <div>
          <ArrowButton handleFunction={() => history.push("/home")} />

          <h1>Ordens de serviço</h1>
        </div>

        {(whoami?.isMe || whoami?.profile?.name === "Sócio") &&
          !user?.profileDisapproved && (
            <S.ButtonAdd to="/service-order/no-linked-budget">
              <HiOutlinePlus size={20} />
            </S.ButtonAdd>
          )}
      </header>

      <S.TabContainer>
        <Tabs
          defaultIndex={
            location.state?.before === "ServiceOrderConcludedDetail"
              ? 1
              : location.state?.before === "ServiceOrderPendingDetail"
              ? 2
              : 0
          }
        >
          <TabList>
            <Tab>Em Andamento</Tab>
            <Tab>Concluídas</Tab>
            <Tab>Arquivadas</Tab>
          </TabList>

          <TabPanel>
            <Pending />
          </TabPanel>

          <TabPanel>
            <Concluded />
          </TabPanel>

          <TabPanel>
            <ArchivedServiceOrder />
          </TabPanel>
        </Tabs>
      </S.TabContainer>
    </S.Container>
  );
}
