import { sha256 } from "js-sha256";
interface IPushDataLayer {
  event: string;
  user: IUserDataLayer;
}

interface IUserDataLayer {
  email?: string;
  phone?: string;
  dateBirth?: string;
  firstName?: string;
  lastName?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  userId?: string;
}

interface SearchDatalayerProps {
  search_term: string;
  success: boolean;
}

function separeteString(fullname?: string) {
  if (fullname) {
    const parts = fullname.split(" ");
    if (parts.length === 1) {
      return { firstName: parts[0], lastName: "" };
    } else {
      return { firstName: parts[0], lastName: parts.slice(1).join(" ") };
    }
  }
}

const convertStringToSha256 = (string?: string) => {
  if (string) {
    const stringSha256 = sha256(string);
    return stringSha256;
  }

  return "";
};

export function pushDataLayer({ event, user }: IPushDataLayer) {
  const cookiesArray = document.cookie.split(";");

  const cookieConsentPerformance = cookiesArray.find((cookie) =>
    cookie.includes(" _cookie_consent_performance=true")
  );

  if (!cookieConsentPerformance) {
    return;
  }

  const firstName = separeteString(user?.firstName)?.firstName || "";
  const lastName = separeteString(user?.lastName)?.lastName || "";

  return window.dataLayer.push({
    event,
    user: {
      email: user?.email || "",
      phone: user?.phone || "",
      dateBirth: user?.dateBirth || "",
      firstName,
      lastName,
      city: user?.city || "",
      state: user?.state || "",
      zip: user?.zip || "",
      country: user?.country || "",
      userId: user?.userId || "",
      loginStatus: true,
    },
    userHash: {
      email: convertStringToSha256(user?.email),
      phone: convertStringToSha256(user?.phone),
      dateBirth: convertStringToSha256(user?.dateBirth),
      firstName: convertStringToSha256(firstName),
      lastName: convertStringToSha256(lastName),
      city: convertStringToSha256(user?.city),
      state: convertStringToSha256(user?.state),
      zip: convertStringToSha256(user?.zip),
      country: convertStringToSha256(user?.country),
      userId: convertStringToSha256(user?.userId),
      loginStatus: convertStringToSha256("true"),
    },
  });
}

export function searchDatalayer({
  search_term,
  success,
}: SearchDatalayerProps) {
  const cookiesArray = document.cookie.split(";");

  const cookieConsentPerformance = cookiesArray.find((cookie) =>
    cookie.includes(" _cookie_consent_performance=true")
  );

  if (!cookieConsentPerformance) {
    return;
  }

  if (success) {
    return window.dataLayer.push({
      event: "search",
      search_term,
    });
  }
}
