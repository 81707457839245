import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import { format, parseISO } from "date-fns";

import DownloadSvg from "assets/icons/download-button.svg";

import { EmptyPage } from "components/EmptyPage";
import { LoadingProfiz } from "components/LoadingProfiz";
import { SearchInput } from "components/SearchInput";
import { TablePagination } from "components/TablePagination";
import { ModalRejectedRegistration } from "components/ModalRejectedRegistration";

import { useToast } from "hooks/toast";
import { useAuth } from "hooks/auth";
import { useAccount } from "hooks/permission/account";

import { ServiceDTO } from "dtos/ServiceDTO";
import { ProductResponseProps } from "dtos/businessProposalsDTO";

import apiv2 from "services/apiv2";

import * as S from "./styles";
import { searchDatalayer } from "utils/pushDataLayer";

type ServiceItemProps = Omit<
  ServiceDTO,
  "quantity" | "total" | "formattedTotal" | "checked"
>;

type BudgetResponse = {
  id: number;
  sequenceNumber: number;
  detailLink: string;
  client: string;
  created_at: string;
  services: {
    idBudgetService: number;
    quantity: number;
    service: ServiceItemProps;
  }[];
  products: ProductResponseProps[];
};

export function Concluded() {
  const { user } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const { whoami } = useAccount();

  const [isLoading, setIsLoading] = useState(true);
  const [allBudgetsConcluded, setAllBudgetsConcluded] = useState<
    BudgetResponse[]
  >([]);

  const [searchInputValue, setSearchInputValue] = useState("");
  const [inputLoading, setInputLoading] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const searchLimit = 50;
  const [activeModalRejectedRegistration, setActiveModalRejectedRegistration] =
    useState(false);

  useEffect(() => {
    getConcludedBudgets();
  }, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getConcludedBudgets() {
    try {
      setIsLoading(true);
      const response = await apiv2.get(`budgets`, {
        params: {
          status: "concluded",
          offset: offset,
          limit: searchLimit,
          accountId: whoami?.id,
        },
      });

      setTotalItems(response.data.info.total);

      setAllBudgetsConcluded(response.data.orcamentos);
    } catch (error) {
      addToast({
        title: "Ops!!",
        description: "Ocorreu um erro ao listar os orçamentos aprovados",
        type: "error",
      });
    } finally {
      setIsLoading(false);
      setInputLoading(false);
    }
  }

  function handleNavigateToBudgetDetails(idBudget: number) {
    if (user?.profileDisapproved) {
      setActiveModalRejectedRegistration(true);
    } else {
      history.push(`/budgets/concluded/${idBudget}`);
    }
  }

  function handleSearchCancel() {
    setSearchInputValue("");
    handleSearchServiceOrderByClient("");
  }

  function handleChange(searchValue: string) {
    setInputLoading(true);
    setSearchInputValue(searchValue);
    debounceFn(searchValue);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    debounce(handleSearchServiceOrderByClient, 3000),
    []
  );

  async function handleSearchServiceOrderByClient(client: string) {
    if (client === "") {
      getConcludedBudgets();
      return;
    }

    try {
      const response = await apiv2.get(`budgets`, {
        params: {
          offset: 0,
          limit: searchLimit,
          search: client,
          archive: false,
          status: "concluded",
          accountId: whoami?.id,
        },
      });

      const { orcamentos } = response.data;
      setTotalItems(response.data.info.total);

      setAllBudgetsConcluded(orcamentos);
      searchDatalayer({ search_term: client, success: true });
    } catch (error) {
      addToast({
        type: "error",
        title: "Não foi possível realizar a busca.",
      });
    } finally {
      setIsLoading(false);
      setInputLoading(false);
    }
  }

  function handlePageChange(pageNumber: number, itemsPerPage: number) {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * itemsPerPage);
  }

  function handleOpenPdfPage(pdfLink: string) {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: pdfLink,
      },
    });
  }

  return (
    <>
      <ModalRejectedRegistration
        isVisible={activeModalRejectedRegistration}
        setIsActive={setActiveModalRejectedRegistration}
      />
      {isLoading ? (
        <LoadingProfiz isVisible={isLoading} />
      ) : (
        <S.Content>
          <S.ContainerSearch>
            <SearchInput
              loadingInput={inputLoading}
              searchValue={searchInputValue}
              placeholder="Procure pelo nome ou endereço do cliente"
              onChange={(event) => handleChange(event.target.value)}
              // eslint-disable-next-line react/jsx-no-bind
              handleCancel={handleSearchCancel}
            />
          </S.ContainerSearch>

          {allBudgetsConcluded.length === 0 ? (
            <EmptyPage />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Número</th>

                  <th> Produto </th>
                  <th> Serviço </th>
                  <th> Cliente </th>
                  <th> Data </th>
                  <th> Status </th>
                  {user?.profileDisapproved && <th> PDF </th>}
                </tr>
              </thead>

              <tbody>
                {allBudgetsConcluded.map(
                  ({
                    id,
                    sequenceNumber,
                    services,
                    products,
                    client,
                    created_at,
                    detailLink,
                  }) => {
                    const productNamesJoined = products
                      .map(({ product }) => product.name)
                      .join(", ");

                    const serviceNamesJoined = services
                      .map(({ service }) => service.service.name)
                      .join(", ");

                    return (
                      <tr
                        key={sequenceNumber}
                        onClick={() => handleNavigateToBudgetDetails(id)}
                      >
                        <td className="columm-id">{sequenceNumber}</td>

                        <td>
                          {products.length === 0 ? (
                            <p style={{ textAlign: "center" }}>---</p>
                          ) : (
                            <p>
                              {productNamesJoined.length > 42
                                ? productNamesJoined.slice(0, 39) + "..."
                                : productNamesJoined}
                            </p>
                          )}
                        </td>
                        <td>
                          {services.length === 0 ? (
                            <p style={{ textAlign: "center" }}>---</p>
                          ) : (
                            <p>
                              {serviceNamesJoined.length > 42
                                ? serviceNamesJoined.slice(0, 39) + "..."
                                : serviceNamesJoined}
                            </p>
                          )}
                        </td>

                        <td>{client}</td>
                        <td>{format(parseISO(created_at), "dd/MM/yyyy")}</td>

                        <td>Concluído</td>
                        {user?.profileDisapproved && (
                          <td
                            onClick={() => handleOpenPdfPage(detailLink)}
                            style={{
                              width: 70,
                            }}
                          >
                            <S.ContainerImage>
                              <img src={DownloadSvg} alt="Download" />
                            </S.ContainerImage>
                          </td>
                        )}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          )}

          <S.ContainerPagination>
            <TablePagination
              rowsLength={totalItems}
              rowsPerPage={searchLimit}
              rowsPage={currentPage}
              handlePageChange={(pageNumber, itemsPerPage) =>
                handlePageChange(pageNumber, itemsPerPage)
              }
            />
          </S.ContainerPagination>
        </S.Content>
      )}
    </>
  );
}
